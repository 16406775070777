import qs from 'qs'
import pako from 'pako'
import minimatch from 'minimatch'
import md5 from 'crypto-js/md5'
import store from '@/store'
import { salt, channel, tokenName, cacheWhiteList, loadingText as loadingMsg } from '@/config'
import { HttpStatusCode } from '@/constants'
import { Loading } from 'element-ui'
import i18n from '@/locale'

// 添加签名
export function handleMd5Sign(config) {
  let sign = ''
  if (config.method.toLocaleLowerCase() !== 'get' && config.data) {
    if (config.data !== null && typeof config.data === 'object') {
      config.data = JSON.stringify(config.data)
    }
    sign = config.data
  } else if (config.params !== null && typeof config.params === 'object') {
    sign = qs.stringify(config.params, { addQueryPrefix: true, skipNulls: true, encode: false })
  }
  //当sign是纯数字是需要转成字符串
  sign = !isNaN(sign) ? String(sign) : sign
  const match = sign?.match(/[a-zA-Z0-9\u4e00-\u9fa5]/g)
  sign = match ? match.join('') : ''
  sign = md5(config.url + sign + salt).toString()
  const timestamp = new Date().getTime()
  config.headers['x-timestamp'] = timestamp
  config.headers['x-sign'] = md5(sign + timestamp).toString()
  return config
}

// 添加请求头
export function addHeader(config) {
  const accessToken = store.getters['user/accessToken']
  if (accessToken) {
    config.headers[tokenName] = accessToken
  }
  const belongId = store.getters['user/belongId']
  if (belongId) {
    config.headers[`x-csp-belongId`] = belongId
  }
  const lang = store.getters['sys/lang']
  if (lang) {
    config.headers[`Accept-Language`] = {
      'zh-CN': 'zh-CN',
      'en-US': 'en-US',
      id: 'id-ID',
    }[lang]
  }
  const belongCode = store.getters['user/belongCode']
  if (belongCode) {
    config.headers[`x-partner-code`] = belongCode
  }
  config.headers['x-ext-channel'] = channel
  return config
}

// 压缩大数据请求体
export function gzipRequestBody(config) {
  try {
    const gzipConfig = store.getters['sys/gzipConfig']
    // 判断当前请求url是否需要压缩
    if (gzipConfig && gzipConfig?.matchUris.some((rule) => minimatch(config.url, rule))) {
      // 并且请求体大小是否超过 50kb(后台默认给的值)
      if (Buffer.from(config.data, 'utf-8').byteLength > 1024 * gzipConfig.thresholdKbSize) {
        config.headers['Content-Encoding'] = 'gzip'
        const bytes = pako.gzip(config.data)
        config.data = bytes
      }
    }
  } catch (error) {
    console.warn('压缩请求体失败')
  }
  return config
}

// 缓存接口请求数据
export async function addCache(config) {
  if (cacheWhiteList?.includes(config.url)) {
    // TODO 判断当前缓存是否过期，如果过期需要重新调用接口更新缓存
    const data = await LocalForage.getItem(config.url)
    if (data) {
      config.adapter = function (c) {
        return new Promise((resolve) => {
          resolve({
            config: c,
            data: {
              code: HttpStatusCode.CACHE_SUCCESS,
              data,
              msg: 'from cache',
            },
          })
        })
      }
    }
  }
  return config
}

// 显示全局loading
export function loading(config) {
  const { loading, loadingText } = config
  if (loading && !config.loadingInstance) {
    config.loadingInstance = Loading.service({ fullscreen: true, text: loadingText || i18n.t(loadingMsg) })
  }
  return config
}

// 统一处理GET方法，url路径最后一个是undefined的问题
export function validateUrl(config) {
  if(config.method.toLocaleLowerCase() !== 'get' || !config.url.endsWith("undefined")) {
    return config
  }
  return Promise.reject({message: '数据可能存在错误,请刷新页面或者重试!'})
}
