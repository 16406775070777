const { HttpStatusCode } = require('@/constants/index')
const salt = process.env.X_WEB_SECRET || process.env.VUE_APP_X_WEB_SECRET
const channel = process.env.X_WEB_CHANNEL || process.env.VUE_APP_X_WEB_CHANNEL
module.exports = {
  salt,
  channel,
  baseURL: '/',
  /** 默认超时时间10分钟 */
  requestTimeout: 1000 * 60 * 10,
  /** 默认 application/json */
  contentType: 'application/json;charset=UTF-8',
  /** 接口成功返回的code码 */
  successCodes: [HttpStatusCode.SUCCESS, HttpStatusCode.CACHE_SUCCESS],
  /** 忽略接口错误提示的code码 */
  ignoreCodes: [4002630, 4002631, 4003030, 4003035, 40036260, 40030260, 4006023, 4006044, 40030435],
  /** 需要重新登录的code码 */
  loginBackInCodes: [4011021, 4011155, 4001013],
  /** 缓存数据白名单 */
  cacheWhiteList: [],
  /** 全局 loading 文本 */
  loadingText: '加急处理中',
  /** 需要语音播报的接口列表 */
  speechApiLists: ['/mg/daohang-order-api/recheckOrder/skuScan'],
}
