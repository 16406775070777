import request from '@/utils/request'
/**
 * 根据业务模块保存表格配置
 * @param {Object} data
 * @returns
 */
export function saveGlobalTableConfig(data) {
  return request({
    url: '/mg/daohang-basic-api/globalTableConfig',
    method: 'put',
    data,
  })
}
/**
 * 根据业务模块获取表格配置
 * @param {Object} data
 * @returns
 */
export function getGlobalTableConfig(businessType) {
  return request({
    url: `/mg/daohang-basic-api/globalTableConfig/get/${businessType}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 获取门户网站图片
 * @param {*} data
 * @returns
 */
export function getPortalImages(data) {
  return request({
    url: '/mg/drp-admin-api/portal/page',
    method: 'post',
    data,
  })
}

/**
 * 获取gzip配置
 * @returns
 */
export function getGzipConfig() {
  return request({
    url: `/mg/system/systemConfig`,
    method: 'get',
  })
}

export function getOssHost(params) {
  return request({
    url: '/mg/drp-enterprise-api/componentFile/requestAliyunOssHost',
    method: 'get',
    params: params,
  })
}
export function getAliyunToken(data) {
  return request({
    url: '/mg/drp-enterprise-api/componentFile/requestSTSToken',
    method: 'post',
    data,
  })
}

export function getCollectList() {
  return request({
    url: '/mg/daohang-basic-api/favorites/list',
    method: 'post',
  })
}

export function updateCollectList(data) {
  return request({
    url: '/mg/daohang-basic-api/favorites/update',
    method: 'put',
    data,
  })
}
/**
 * 获取下载PDA的下载链接
 * @returns
 */
export function getPDADownloadPath() {
  return request({
    url: '/mg/daohang-basic-api/appUpgrade/latestApkPath',
    method: 'get',
  })
}
/**
 * 获取获取下载中心列表
 * @returns
 */
export function excelTaskPage(data) {
  return request({
    url: '/mg/daohang-basic-api/excelTask/page',
    method: 'post',
    data,
  })
}

/**
 * 获取国外往来单位编码
 * @returns
 */
export function getPartnerRegion() {
  return request({
    url: '/mg/daohang-basic-api/onlinePartner/getPartnerRegion',
    method: 'get',
  })
}
