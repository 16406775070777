import request from '@/utils/request'

export function currencyList(data) {
  return request({
    url: '/mg/daohang-basic-api/currency/list',
    method: 'post',
    data,
  })
}

export function getCurrencyCode(code) {
  return request({
    url: `/mg/daohang-basic-api/currency/queryByCode/${code}`,
    method: 'get',
  })
}

/**
 * 经营货币
 * @param {*} data
 * @returns
 */
export function operationCurrencyList(data) {
  return request({
    url: '/mg/daohang-basic-api/partnerCurrency/getExchangeRate',
    method: 'post',
    data,
  })
}
/**
 * 获取往来单位下的货币列表
 * @param {*} data
 * @returns
 */
export function getPartnerCurrencyList(data) {
  return request({
    url: '/mg/daohang-basic-api/partnerCurrency/list',
    method: 'post',
    data,
  })
}
